import React from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";

//Providers
import { store } from "../../core/redux/store.jsx";
import { getUserCompany, getUser } from "../../core/redux/slices/authSlice";

const Profile = () => {
  const user = getUser(store.getState());

  const capitalizeFullName = (fullName) => {
    return fullName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Profile</h4>
            <h6>User Profile</h6>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-top">
                <div className="profile-content">
                  <div className="profile-contentname mt-2">
                    <h2>{user.name.toUpperCase()}</h2>
                    <h4>User Personal Details.</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    defaultValue={user.name}
                    value={capitalizeFullName(user.name)}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    defaultValue={user.email}
                    value={user.email}
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">Role</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={user.role}
                    value={user.role.toUpperCase()}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default Profile;

import {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { store } from "../core/redux/store";
import { getEntity, getToken } from "../core/redux/slices/authSlice";

import toast from "react-hot-toast";

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const accessToken = getToken(store.getState());
  const entity = getEntity(store.getState());
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers["X-Entity"] = entity;
  }

  //console.info(`[request] [${JSON.stringify(config)}]`);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  //console.error(`[request error] [${JSON.stringify(error)}]`);

  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  response.data.message !== true && toast.success(response.data.message);

  //console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: any): Promise<AxiosError> => {
  if (error.response && error.response.status === 401) {
    window.location.href = "/";
  } else if (
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  ) {
    toast.error(error.response.data.message);
  } else {
    //console.error(`[response error] [${JSON.stringify(error)}]`);
  }

  return Promise.reject(error);
};

export function setupInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { setupInterceptors } from "../../../lib/interceptor.tsx";
import { baseUrl } from "../../../api/apiConfig.js";
import { Uppy } from "@uppy/core";
import Webcam from "@uppy/webcam";
import { Dashboard } from "@uppy/react";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import RemoteSources from "@uppy/remote-sources";
import DefaultStore from "@uppy/store-default";
import { store } from "../../redux/store.jsx";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import {
  getCurrent,
  setUploads,
  resetUploads,
} from "../../redux/slices/folderSlice.jsx";

const axiosInstance = axios.create();
setupInterceptors(axiosInstance);

const FileModal = () => {
  const dispatch = useDispatch();
  const getParentId = useSelector(getCurrent);

  const [localFormData, setLocalFormData] = useState({
    name: "",
    type: "folder",
  });

  const COMPANION_URL = `${baseUrl}/files/confirm-signed-upload`;
  const companionAllowedHosts = [];
  const MiB = 0x10_00_00;

  const [uppy] = useState(() =>
    new Uppy({
      debug: false,
      store: new DefaultStore(),
    })
      .use(Webcam, {
        showVideoSourceDropdown: true,
        showRecordingLength: true,
      })
      .use(RemoteSources, {
        companionUrl: COMPANION_URL,
        sources: ["Box", "Dropbox", "GoogleDrive", "OneDrive", "Url"],
        companionAllowedHosts,
      })
      .use(AwsS3Multipart, {
        id: "aejayAWS",
        direct: false,
        method: "PUT",
        shouldUseMultipart: (file) => file.size > 100 * MiB,
        // Non Multi-part
        async getUploadParameters(file, options) {
          const parentId = getCurrent(store.getState());

          const response = await axiosInstance.post(
            `${baseUrl}/files/presigned-url`,
            {
              filename: file.name,
              filetype: file.type,
              size: file.size,
              parent_id: parentId,
            },
            {
              signal: options.signal,
            }
          );

          if (response.status !== 200) {
            throw new Error("Unsuccessful request", {
              cause: response.data.message,
            });
          }

          const { url } = response.data.data;

          return {
            method: "PUT",
            url: url,
            fields: {},
            headers: {
              "Content-Type": file.type,
            },
          };
        },
        // Multi-part
        async createMultipartUpload(file, signal) {
          signal?.throwIfAborted();

          const parentId = getCurrent(store.getState());

          const response = await axiosInstance.post(
            `${baseUrl}/files/start-chunk-upload`,
            {
              filename: file.name,
              filetype: file.type,
              size: file.size,
              parent_id: parentId,
            },
            {
              signal,
            }
          );

          if (response.status !== 200) {
            throw new Error("Unsuccessful request", {
              cause: response.data.message,
            });
          }

          const { upload_id, key } = response.data.data;

          return {
            uploadId: upload_id,
            key: key,
          };
        },
        async abortMultipartUpload(file, { key, uploadId, signal }) {
          const response = await axiosInstance.post(
            `${baseUrl}/files/abort-chunk-upload`,
            {
              key,
              upload_id: uploadId,
            },
            {
              signal,
            }
          );

          if (response.status !== 202)
            throw new Error("Unsuccessful request", { cause: response });
        },
        async signPart(file, options) {
          const { uploadId, key, partNumber, signal } = options;

          signal?.throwIfAborted();

          if (uploadId == null || partNumber == null || key == null) {
            throw new Error(
              "Cannot sign without a key, an uploadId, and a partNumber"
            );
          }

          const response = await axiosInstance.post(
            `${baseUrl}/files/chunk-signed-upload`,
            {
              upload_id: uploadId,
              key,
              part_number: partNumber,
            },
            {
              signal,
            }
          );

          if (response.status !== 200)
            throw new Error("Unsuccessful request", { cause: response });

          return response.data.data;
        },
        async listParts(file, { key, uploadId, signal }) {
          signal?.throwIfAborted();

          const response = await axiosInstance.post(
            `${baseUrl}/files/resume-signed-upload`,
            {
              key,
              upload_id: uploadId,
            },
            {
              signal,
            }
          );

          if (response.status !== 200)
            throw new Error("Unsuccessful request", { cause: response });

          const { parts } = response.data.data;
          return parts;
        },
        async completeMultipartUpload(file, { uploadId, key, parts }) {
          const response = await axiosInstance.post(
            `${baseUrl}/files/confirm-signed-upload`,
            {
              upload_id: uploadId,
              key,
              parts,
            }
          );

          if (response.status !== 201)
            throw new Error("Unsuccessful request", { cause: response });
          else {
            return response.data.data;
          }
        },
      })
  );

  uppy.on("complete", (result) => {
    dispatch(resetUploads());
    //console.log("Status:", result.successful);
  });

  uppy.on("upload-success", (file, response) => {
    dispatch(setUploads());
    //console.log(response, file);
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setLocalFormData({ ...localFormData, [name]: value });

    if (typeof onInputChange === "function") {
      onInputChange({ ...externalFormData, [name]: value });
    }
  };

  const createFolder = () => {
    if (localFormData.name.trim() === "") {
      return;
    }

    axiosInstance
      .post(`${baseUrl}/files`, { ...localFormData, parent_id: getParentId })
      .then((response) => {
        dispatch(setUploads());
        setLocalFormData({ ...localFormData, name: "" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {/* Upload File */}
      <div
        className="modal fade modal-default pos-modal upload-modal"
        id="upload-file"
        aria-labelledby="upload-file"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-4 d-flex align-items-center justify-content-between">
              <h5>Upload File</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <Dashboard
              id="dashboard"
              uppy={uppy}
              inline={true}
              showProgressDetails={true}
            />
          </div>
        </div>
      </div>
      {/* /Upload File */}
      {/* Create Folder */}
      <div
        className="modal fade modal-default pos-modal"
        id="create-folder"
        aria-labelledby="create-folder"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-4 d-flex align-items-center justify-content-between">
              <h5>Create Folder</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-12">
                  <label className="form-label">Folder Name</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={localFormData.name}
                  />
                </div>
              </div>
              <div className="modal-footer d-sm-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => createFolder()}
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Create Folder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Create Folder */}
    </div>
  );
};

export default FileModal;

import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../feature-module/loader/loader";
import BasePage from "../feature-module/filemanager/base_page";
import Signin from "../feature-module/pages/login/signin";
import Emailverification from "../feature-module/pages/emailverification/emailverification";
import Forgotpassword from "../feature-module/pages/forgotpassword/forgotpassword";

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);

  return (
    <div>
      <Routes>
        <Route path={"/"} element={<Signin />} />
        <Route path={"dashboard/*"} element={<BasePage />} />
        <Route path={"emailverification"} element={<Emailverification />} />
        <Route path={"forgot-password"} element={<Forgotpassword />} />{" "}
      </Routes>
    </div>
  );
};
export default AllRoutes;

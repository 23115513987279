import React from "react";
import {
  Edit,
  List,
  Clock,
  XCircle,
  HardDrive,
  Calendar,
  PlusCircle,
} from "react-feather";
import { Link } from "react-router-dom";
import { extensionImageMap } from "../../lib/types/extensions.tsx";

export const Details = ({ filePreview = {} }) => {
  const {
    name = "",
    type = "",
    size = "",
    created_at = "",
    location = "",
    updated_at = "",
    description = "",
    accessList = [],
    thisWeekActivities = [],
    lastMonthActivities = [],
    owner = "",
  } = filePreview;

  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };

  const fileExtension = getFileExtension(name);

  const imageSrc = extensionImageMap[fileExtension] || extensionImageMap.folder;

  return (
    <>
      {/* end offcanvas*/}
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header d-flex justify-content-between">
          <div className="offcanvasRightLabel">
            <h4>File Preview</h4>
          </div>
          <div className="d-flex offcanvas-end">
            {/* <Link to="#" className="me-2 d-flex align-items-center">
              <Star className="feather-16 text-center" />
            </Link>
            <Link to="#" className="me-2 d-flex align-items-center">
              <Trash2 className="feather-16 text-center text-danger" />
            </Link> */}
            <Link
              to="#"
              className="d-flex align-items-center text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <XCircle className="feather-16 text-center text-danger" />
            </Link>
          </div>
        </div>{" "}
        {/* end offcanvas-header*/}
        <div className="offcanvas-body">
          <div
            className="nav nav-tabs d-flex align-items-center justify-content-between py-4 mb-4"
            id="nav-tab"
            role="tablist"
          >
            <Link
              className="nav-link flex-fill active btn btn-light me-2 text-center"
              id="nav-home-tab"
              data-bs-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              <List
                data-feather="list"
                className="feather-16 me-2 text-center"
              />
              Details
            </Link>
            <Link
              className="nav-link flex-fill btn btn-light"
              id="nav-profile-tab"
              data-bs-toggle="tab"
              to="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              <Clock className="feather-16 me-2" />
              Activity
            </Link>
          </div>
          <div className="text-center">
            <a href="">
              <img src={imageSrc} width={104} height={104} alt="" />
            </a>
            <h5>{name}</h5>
            <p>File Size : {size}</p>
          </div>

          {/* Details tab */}
          <div
            className="tab-pane fade show active my-4"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <h5 className="mb-4 d-flex align-items-center">
              <Edit />
              Properties
            </h5>
            <ul className="seprator-lg">
              {/* File properties */}
              <li className="mb-4">
                <h6>File Name</h6>
                <p>{name}</p>
              </li>
              <li className="mb-4">
                <h6>File Type</h6>
                <p>{type}</p>
              </li>
              <li className="mb-4">
                <h6>Size</h6>
                <p>{size}</p>
              </li>
              <li className="mb-4">
                <h6>Created</h6>
                <p>{created_at}</p>
              </li>
              <li className="mb-4">
                <h6>Location</h6>
                <p className="location d-inline-flex align-items-center">
                  <HardDrive className="feather-16 me-1" />
                  {location}
                </p>
              </li>
              <li className="mb-4">
                <h6>Last Modified</h6>
                <p>{updated_at}</p>
              </li>
            </ul>
          </div>
          {/* Activity tab */}
          <div
            className="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <h5 className="mb-4 d-flex align-items-center">
              <i data-feather="calendar" className="feather-20 me-2"></i>This
              Week
            </h5>
            <ul className="mb-4">
              {thisWeekActivities.map((activity, index) => (
                <li key={index} className="mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <a href="" className="me-3">
                      <img
                        src={activity.avatarSrc}
                        alt="Avatar"
                        className="avatar-md"
                      />
                    </a>
                    <p>
                      {activity.name} {activity.action} on 1 item
                      <br />
                      {activity.time}
                    </p>
                  </div>
                  <p className="d-flex align-items-center location border-0">
                    <img src={activity.iconSrc} alt="Folder" className="me-2" />
                    {activity.fileName}
                  </p>
                </li>
              ))}
            </ul>
            <h5 className="mb-4 d-flex align-items-center">
              <Calendar className="feather-20 me-2" />
              Last Month
            </h5>
            <ul className="mb-4">
              {lastMonthActivities.map((activity, index) => (
                <li key={index} className="mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <Link to="#" className="me-3">
                      <img
                        src={activity.avatarSrc}
                        alt="Avatar"
                        className="avatar-md"
                      />
                    </Link>
                    <p>
                      {activity.name} {activity.action} on 1 item
                      <br />
                      {activity.time}
                    </p>
                  </div>
                  <p className="d-flex align-items-center location border-0">
                    <img src={activity.iconSrc} alt="Folder" className="me-2" />
                    {activity.fileName}
                  </p>
                </li>
              ))}
            </ul>
            <a href="" className="text-primary show-all">
              <PlusCircle className="feather-20 me-2" />
              Show All
            </a>
          </div>
        </div>{" "}
        {/* end offcanvas-body*/}
      </div>{" "}
      {/* end offcanvas*/}
    </>
  );
};

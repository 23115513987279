import FileContent from "./fileContent";

const FileManager = () => {
  return (
    <div className="row">
      <FileContent />
    </div>
  );
};

export default FileManager;

import { PlusCircle } from "feather-icons-react/build/IconComponents";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { setupInterceptors } from "../../../lib/interceptor.tsx";
import { baseUrl } from "../../../api/apiConfig.js";

const AddUsers = () => {
  const [roles, setRoles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const [userData, setUserData] = useState({
    name: "",
    email: "folder",
    role_id: "",
    password: "",
  });

  const getRoles = () => {
    axiosInstance
      .get(`${baseUrl}/system/roles`)
      .then((res) => {
        if (!res || !res.data || !res.data.data || !res.data.data.data) {
          return;
        }
        const rolesData = res.data.data.data.map((role) => ({
          value: role.id,
          label: role.name,
        }));
        setRoles(rolesData);
      })
      .catch((err) => {
        console.log("Error fetching roles:", err);
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const createUser = (e) => {
    e.preventDefault();
    axiosInstance
      .post(`${baseUrl}/users`, userData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setUserData({ ...userData, [name]: value });
    } else {
      setUserData({ ...userData, role_id: e.value });
    }
  };

  return (
    <div>
      {/* Add User */}
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header d-flex align-items-center justify-content-between">
                  <div className="page-title">
                    <h4>Add User</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true ">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>User Name</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            onChange={handleInputChange}
                            value={userData.name}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            onChange={handleInputChange}
                            value={userData.email}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Role</label>

                          <Select
                            name="role_id"
                            className="select"
                            options={roles}
                            placeholder="Choose Status"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Password</label>
                          <div className="pass-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className="pass-input"
                              placeholder="Enter your password"
                              onChange={handleInputChange}
                              value={userData.password}
                              required
                            />
                            <span
                              className={`fas toggle-password ${
                                showPassword ? "fa-eye" : "fa-eye-slash"
                              }`}
                              onClick={handleTogglePassword}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-submit"
                        onClick={createUser}
                      >
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add User */}
    </div>
  );
};

export default AddUsers;

import { createSlice } from "@reduxjs/toolkit";

const folderSlice = createSlice({
  name: "folder",
  initialState: {
    current: null,
    stack: [],
    uploads: 0,
  },
  reducers: {
    setUploads: (state) => {
      state.uploads += 1;
    },
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    resetCurrent: (state) => {
      state.current = "";
    },
    setStack: (state, action) => {
      state.stack.push(action.payload);
    },
    resetStack: (state) => {
      state.stack = [];
    },
    resetUploads: (state) => {
      state.uploads = 0;
    },
    popStack: (state) => {
      state.current = state.stack.pop();
    },
  },
});

export const {
  setCurrent,
  resetCurrent,
  setStack,
  resetStack,
  popStack,
  resetUploads,
  setUploads,
} = folderSlice.actions;
export const getCurrent = (state) => state.folder.current;
export const getStack = (state) => state.folder.stack;
export const getUploads = (state) => state.folder.uploads;
export default folderSlice.reducer;

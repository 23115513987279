import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { setupInterceptors } from "../../../lib/interceptor.tsx";
import { baseUrl } from "../../../api/apiConfig.js";

const EditUser = () => {
  const [roles, setRoles] = useState([]);

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [users, setUsers] = useState(false);

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const getUsers = () => {
    axiosInstance
      .get(`${baseUrl}/users`)
      .then((res) => {
        const users = res.data.data;
        setUsers(users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRoles = () => {
    axiosInstance
      .get(`${baseUrl}/system/roles`)
      .then((res) => {
        if (!res || !res.data || !res.data.data || !res.data.data.data) {
          return;
        }
        const rolesData = res.data.data.data.map((role) => ({
          value: role.id,
          label: role.name,
        }));
        setRoles(rolesData);
      })
      .catch((err) => {
        console.log("Error fetching roles:", err);
      });
  };

  useEffect(() => {
    getUsers();
    getRoles();
  }, []);

  return (
    <div>
      {/* Edit User */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header d-flex justify-content-between">
                  <div className="page-title">
                    <h4>Edit User</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>User Name</label>
                          <input
                            type="text"
                            placeholder="Thomas"
                            value={users.name}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Email</label>
                          <input
                            type="email"
                            placeholder="thomas@example.com"
                            value={users.emal}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Role</label>
                          <Select
                            className="select"
                            options={roles}
                            placeholder="Choose Status"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <Link to="#" className="btn btn-submit">
                        Update
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit User */}
    </div>
  );
};

export default EditUser;

import React, { useState } from "react";
import { baseUrl } from "../../../api/apiConfig.js";
import axios from "axios";
import { setupInterceptors } from "../../../lib/interceptor.tsx";
import { Toaster } from "react-hot-toast";

// Providers
import { store } from "../../../core/redux/store.jsx";
import { getUser } from "../../../core/redux/slices/authSlice";

const ChangePassword = () => {
  const currentYear = new Date().getFullYear();
  const user = getUser(store.getState());

  const [passwordVisibility, setPasswordVisibility] = useState({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const [changePasswordDetails, setChangePasswordDetails] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [loginError, setLoginError] = useState("");
  const [updating, setUpdating] = useState("Update");

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setUpdating("Updating In...");

    try {
      const response = await axiosInstance.put(
        `${baseUrl}/users/${user.id}/change-password`,
        changePasswordDetails
      );
    } catch (error) {
      console.error("Change Password:", error);

      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      setLoginError(errorMessage);
    } finally {
      setUpdating("Update");
    }
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <div className="login-wrapper login-new">
        <div className="login-content user-login">
          <form onSubmit={handleChangePassword}>
            <div className="login-userset">
              <div className="login-userheading">
                <h3>Change Password</h3>
                <h4>Change your password to access the SME Vault panel.</h4>
              </div>
              <div className="form-login">
                <label>Old Password</label>
                <div className="pass-group">
                  <input
                    type={passwordVisibility.old_password ? "text" : "password"}
                    className="pass-input"
                    name="old_password"
                    value={changePasswordDetails.old_password}
                    onChange={(e) =>
                      setChangePasswordDetails({
                        ...changePasswordDetails,
                        old_password: e.target.value,
                      })
                    }
                  />
                  <span
                    className={`fas toggle-password ${
                      passwordVisibility.old_password
                        ? "fa-eye"
                        : "fa-eye-slash"
                    }`}
                    onClick={() => togglePasswordVisibility("old_password")}
                  />
                </div>
              </div>
              <div className="form-login">
                <label>New Password</label>
                <div className="pass-group">
                  <input
                    type={passwordVisibility.new_password ? "text" : "password"}
                    className="pass-input"
                    name="new_password"
                    value={changePasswordDetails.new_password}
                    onChange={(e) =>
                      setChangePasswordDetails({
                        ...changePasswordDetails,
                        new_password: e.target.value,
                      })
                    }
                  />
                  <span
                    className={`fas toggle-password ${
                      passwordVisibility.new_password
                        ? "fa-eye"
                        : "fa-eye-slash"
                    }`}
                    onClick={() => togglePasswordVisibility("new_password")}
                  />
                </div>
              </div>
              <div className="form-login">
                <label>Confirm New Password</label>
                <div className="pass-group">
                  <input
                    type={
                      passwordVisibility.confirm_password ? "text" : "password"
                    }
                    className="pass-input"
                    name="confirm_password"
                    value={changePasswordDetails.confirm_password}
                    onChange={(e) =>
                      setChangePasswordDetails({
                        ...changePasswordDetails,
                        confirm_password: e.target.value,
                      })
                    }
                  />
                  <span
                    className={`fas toggle-password ${
                      passwordVisibility.confirm_password
                        ? "fa-eye"
                        : "fa-eye-slash"
                    }`}
                    onClick={() => togglePasswordVisibility("confirm_password")}
                  />
                </div>
              </div>
              <div className="form-login authentication-check">
                <div className="row">
                  <div className="col-6">
                    <div className="custom-control custom-checkbox"></div>
                  </div>
                </div>
              </div>
              <div className="form-login">
                <button className="btn btn-login" type="submit">
                  {updating === "Update" ? "Update" : "Updating In..."}
                </button>
              </div>
              <div className="signinform"></div>
            </div>
          </form>
          {loginError && (
            <div className="alert alert-danger mt-3">{loginError}</div>
          )}
        </div>
        <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
          <p>Copyright © {currentYear} SME Cloud. All rights reserved</p>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

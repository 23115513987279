import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import ImageWithBasePath from "../../core/img/imagewithbasebath.jsx";

import { baseUrl } from "../../api/apiConfig.js";
import axios from "axios";
import { setupInterceptors } from "../../lib/interceptor.tsx";
import { Trash2, Archive, List } from "react-feather";
import {
  resetCurrent,
  resetStack,
} from "../../core/redux/slices/folderSlice.jsx";
import { extensionImageMap } from "../../lib/types/extensions.tsx";

//Providers
import { store } from "../../core/redux/store.jsx";
import { getUser } from "../../core/redux/slices/authSlice";

const Archived = () => {
  const options1 = [
    { value: "lastModified", label: "Last Modified" },
    { value: "lastModifiedByMe", label: "Last Modified by Me" },
    { value: "lastOpenedByMe", label: "Last Opened by Me" },
  ];

  const [files, setFiles] = useState([]);

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const user = getUser(store.getState());

  const fetchArchivedFiles = () =>
    axiosInstance
      .get(`${baseUrl}/files`, { params: { state: "archived" } })
      .then((response) => {
        const data = response.data.data;
        setFiles(data);
      })
      .catch((error) => {
        console.log(error);
      });

  const deletePermanently = (file_id) => {
    axiosInstance
      .delete(`${baseUrl}/files/action/${file_id}`)
      .then((response) => {
        console.log("deleted", response.data.data);
        fetchFolders();
      })
      .catch((error) => {
        console.log("Error deleting folder", error);
      });
  };

  useEffect(() => {
    store.dispatch(resetCurrent());
    store.dispatch(resetStack());
    fetchArchivedFiles();
  }, []);

  const unArchiveFolder = (folder_id) => {
    const action = "unarchive";

    const payload = {
      action: action,
    };
    axiosInstance
      .post(`${baseUrl}/files/action/${folder_id}`, payload)
      .then((response) => {
        fetchArchivedFiles();
      })
      .catch((error) => {
        console.log("Error archiving folder", error);
      });
  };

  return (
    <>
      <div className={` file-manager `}>
        <div className="card p-4 bg-white all-files mb-0">
          <div className="seprator-lg d-lg-flex align-items-center justify-content-between">
            <h4>Archived Files</h4>
            <div className="d-sm-flex align-items-center btn-grp">
              <Link to="#" className="btn btn-primary me-2">
                <List className="feather-20" />
              </Link>
              {/* <Link to="#" className="btn btn-outline-secondary me-2">
                <Layout className="feather-20" />
              </Link>
              <Link to="#" className="btn btn-outline-secondary me-2">
                <Grid className="feather-20" />
              </Link> */}
              <div className="icon-select">
                {/* <span className="icon">
                  <Calendar className="feather-16" />
                </span> */}
                <Select className="select" options={options1} />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table datanew">
              <thead>
                <tr>
                  <th>
                    <label className="checkboxs">
                      <input type="checkbox" id="select-all" />
                      <span className="checkmarks" />
                    </label>
                  </th>
                  <th>Name</th>
                  <th>Last Modified</th>
                  <th>Size</th>
                  <th>Action</th>
                </tr>
              </thead>
              {files.map((folder, index) => {
                let fileExtension = null;

                const fileNameParts = folder.name.split(".");
                if (fileNameParts.length > 1) {
                  fileExtension = fileNameParts.pop().toLowerCase();
                }

                const imageSrc =
                  folder.type === "folder"
                    ? extensionImageMap["folder"]
                    : extensionImageMap[fileExtension] ||
                      extensionImageMap["document"];
                return (
                  <tbody key={index}>
                    <tr>
                      <td>
                        <label className="checkboxs">
                          <input type="checkbox" />
                          <span className="checkmarks" />
                        </label>
                      </td>
                      <td className="productimgname">
                        <Link
                          to="product-list"
                          className="product-img d-flex align-items-center"
                        >
                          <ImageWithBasePath
                            src={imageSrc}
                            alt="Product"
                            className="me-2"
                          />
                          <span>{folder.name}</span>
                        </Link>
                      </td>
                      <td>
                        {folder.updated_at}
                        <br />
                        {folder.owner.name}
                      </td>
                      <td>{folder.size}</td>
                      <td className="text-end">
                        <div className="d-flex align-items-center">
                          <div className="dropdown">
                            <Link
                              to="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              className="dropset"
                            >
                              <i className="fa fa-ellipsis-v" />
                            </Link>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  onClick={() => deletePermanently(folder.id)}
                                  to="#"
                                  className="dropdown-item"
                                >
                                  <Trash2 className="feather-14 me-2" />
                                  Delete Permanently
                                </Link>
                              </li>
                              <li
                                className=""
                                onClick={() => unArchiveFolder(folder.id)}
                              >
                                <Link
                                  onClick={() => unArchiveFolder(folder.id)}
                                  to="#"
                                  className="dropdown-item"
                                >
                                  <Archive className="feather-14 me-2" />
                                  Unarchive
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Archived;

import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useSearchParams } from "react-router-dom";
import { baseUrl } from "../../../api/apiConfig.js";
import axios from "axios";
import { setupInterceptors } from "../../../lib/interceptor.tsx";

import { Toaster } from "react-hot-toast";

const Forgotpassword = () => {
  const currentYear = new Date().getFullYear();
  const [searchParams] = useSearchParams();
  const paramCode = searchParams.get("code") ?? "";
  const paramUsername = searchParams.get("username") ?? "";

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const [email, setEmail] = useState(paramUsername);
  const [entity, setEntity] = useState(paramCode);

  const sendMail = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post(
        `${baseUrl}/account/reset`,
        {
          email,
        },
        {
          headers: {
            "X-Entity": entity,
          },
        }
      );
      // Handle the response if needed
    } catch (error) {
      console.error("Failed: ", error);
    }
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper login-new">
            <div className="container">
              <div className="login-content user-login">
                <div className="login-logo">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                </div>
                <form onSubmit={sendMail}>
                  <div className="login-userset">
                    <div className="login-userheading">
                      <h3>Forgot password?</h3>
                      <h4>
                        If you forgot your password, well, then we’ll email you
                        instructions to reset your password.
                      </h4>
                    </div>
                    <div className="form-login">
                      <label>Email</label>
                      <div className="form-addons">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <ImageWithBasePath
                          src="assets/img/icons/mail.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="form-login">
                      <label>Entity Code</label>
                      <div className="form-addons">
                        <input
                          type="entity"
                          name="entity"
                          className="form-control"
                          value={entity}
                          onChange={(e) => setEntity(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-login">
                      <button type="submit" className="btn btn-login">
                        Send Mail
                      </button>
                    </div>
                    <div className="signinform text-center">
                      <h4>
                        Return to
                        <Link to="/" className="hover-a">
                          {" "}
                          login{" "}
                        </Link>
                      </h4>
                    </div>
                  </div>
                </form>
              </div>
              <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                <p>Copyright © {currentYear} SME Cloud. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpassword;

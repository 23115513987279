import React, { useState } from "react";

import { Link } from "react-router-dom";
import Loader from "../../feature-module/loader/loader";

import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import FileModal from "../../core/modals/applications/fileModal";
import { Toaster } from "react-hot-toast";

import Header from "../../InitialPage/Sidebar/Header";
import {
  File,
  Folder,
  FolderMinus,
  HardDrive,
  PlusCircle,
  Repeat,
  UploadCloud,
  FileText,
  Target,
  Trash2,
  Star,
  RotateCcw,
  Codesandbox,
} from "react-feather";
import Favourites from "./favorite";
import Shared from "./shared";
import Archived from "./archived";
import Deleted from "./deleted";
import MyFiles from "./myfiles";

//Providers
import { store } from "../../core/redux/store.jsx";
import { getUser, getUserStorage } from "../../core/redux/slices/authSlice";
import Profile from "../pages/profile.jsx";
import FileTable from "./filetable.jsx";
import FileManager from "../Application/filemanager.jsx";
import Signin from "../pages/login/signin.jsx";
import ChangePassword from "../pages/changepassword/changepassword.jsx";
import Users from "../usermanagement/users.jsx";

const BasePage = () => {
  const [isOpen, setOpen] = useState(false);
  const data = useSelector((state) => state.toggle_header);

  const storage = getUserStorage(store.getState());
  const user = getUser(store.getState());

  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "Ascending", label: "Ascending" },
    { value: "Descending", label: "Descending" },
    { value: "Recently Viewed", label: "Recently Viewed" },
    { value: "Recently Added", label: "Recently Added" },
    { value: "Creation Date", label: "Creation Date" },
  ];

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );

  const reloadPage = () => {
    window.location.reload();
  };

  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      <Header />
      <div className="horizontal-sidebar"></div>
    </div>
  );

  return (
    <>
      <HeaderLayout />
      <div
        className={`page-wrapper notes-page-wrapper file-manager ${
          isOpen && "notes-tag-left"
        }`}
      >
        <div className="content">
          <div className="page-header page-add-notes flex-sm-row flex-column">
            <div className="add-item d-flex">
              <div className="page-title">
                <Link to="">
                  <h4>File Manager</h4>
                </Link>
                <p>Manage your files</p>
              </div>
              <Link
                id="toggle_btn2"
                className="notes-tog"
                to="#"
                onClick={() => setOpen(!isOpen)}
              >
                <i className="fas fa-chevron-right" />
              </Link>
            </div>
            <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-start">
              <ul className="table-top-head">
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderRefreshTooltip}
                  >
                    <Link
                      onClick={reloadPage}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                    >
                      <RotateCcw />
                    </Link>
                  </OverlayTrigger>
                </li>
              </ul>
              <Link
                to="#"
                className="btn btn-primary btn-added"
                data-bs-toggle="modal"
                data-bs-target="#upload-file"
              >
                <span className="me-1 d-flex align-items-center">
                  <UploadCloud className="feather-16 me-2" />
                </span>
                Upload Files
              </Link>
            </div>
          </div>
          <div className="row">
            <div
              className={`col-lg-3 col-md-12 sidebars-right theiaStickySidebar section-bulk-widget  ${
                isOpen && "section-notes-dashboard"
              }`}
            >
              <aside className="card file-manager-sidebar mb-0">
                <h5 className="d-flex align-items-center">
                  <span className="me-2 d-flex align-items-center">
                    <Folder className="feather-20" />
                  </span>
                  Files
                </h5>
                <div className="dropdown">
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="dropset btn btn-primary mb-3 btn-icon"
                  >
                    <span className="me-1 btn-icon">
                      <PlusCircle className="feather-16" />
                    </span>
                    New
                  </Link>
                  <ul className="dropdown-menu">
                    <li data-bs-toggle="modal" data-bs-target="#upload-file">
                      <Link to="#" className="dropdown-item">
                        <UploadCloud className="feather-16 me-2" />
                        Upload File
                      </Link>
                    </li>
                    <li data-bs-toggle="modal" data-bs-target="#create-folder">
                      <Link to="#" className="dropdown-item">
                        <FolderMinus className="feather-16 me-2" />
                        Create folder
                      </Link>
                    </li>
                  </ul>
                </div>
                <ul className="mb-5">
                  <li>
                    <Link to="/dashboard">
                      <span className="me-2 btn-icon">
                        <Codesandbox className="feather-16" />
                      </span>
                      Overview
                    </Link>
                  </li>
                  <li>
                    <Link to="myfiles">
                      <span className="me-2 btn-icon">
                        <FileText className="feather-16" />
                      </span>
                      My Files
                    </Link>
                  </li>
                  {user.role === "Super Admin" && (
                    <li>
                      <Link to="shared">
                        <span className="me-2 btn-icon">
                          <File className="feather-16" />
                        </span>
                        Shared With Me
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link to="favourites">
                      <span className="me-2 btn-icon">
                        <Star className="feather-16" />
                      </span>
                      Favourites
                    </Link>
                  </li>
                  <li>
                    <Link to="archived">
                      <span className="me-2 btn-icon">
                        <Target className="feather-16" />
                      </span>
                      Archived
                    </Link>
                  </li>
                  <li>
                    <Link to="deleted">
                      <span className="me-2 btn-icon">
                        <Trash2 className="feather-16" />
                      </span>
                      Deleted
                    </Link>
                  </li>
                </ul>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <span className="me-2 btn-icon">
                      <HardDrive className="feather-16" />
                    </span>
                    <h6>Storage</h6>
                  </div>
                  <span>{storage.usedPercentage}</span>
                </div>
                <div className="progress my-2">
                  <div
                    className="progress-bar progress-bar bg-danger"
                    role="progressbar"
                    style={{ width: `${storage.usedPercentage}` }}
                    aria-valuenow={storage.total}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <span>
                  {storage.used} of {storage.total} Used
                </span>
                <div className="space-manager p-4 my-2">
                  <ImageWithBasePath
                    src="assets/img/icons/half-circle.svg"
                    alt="Folder"
                  />
                  <h6>
                    <Link to="#">Need More Space?</Link>
                  </h6>
                  <p>Upgrade storage for 1TB at $250 per year</p>
                  <Link
                    to="https://checkout.stripe.com/c/pay/cs_live_a1MFrzJKJqZOFwf5kAKRJIR6XSQfPYcHSdERWFYERPn7jzdnsdpJLNVcbz#fidkdWxOYHwnPyd1blppbHNgWjA0Sn0xXXVCdX9qXE5Ra2NUb2ZTY1FrUmxDcnx0QF90YWlWbEtjZkQyYklCaX9MMkpoZEZyTDU1bUh0b1FpPGpAcH1zSG1IR0l2bmNLbzBpPDNtVW0wblI8NTV0XWRkR3VCbScpJ3ZwZ3Zmd2x1cWxqa1BrbHRwYGtgdnZAa2RnaWBhJz9jZGl2YHgl"
                    target="_blank"
                    className="btn btn-outline-primary"
                  >
                    Upgrade
                    <span className="ms-1 btn-icon">
                      <Repeat className="feather-16" />
                    </span>
                  </Link>
                </div>
              </aside>
            </div>
            <div className={`col-lg-9 budget-role-notes`}>
              <Routes>
                <Route path="/" element={<FileManager />} />
                <Route path="myfiles" element={<MyFiles />} />
                <Route path="signin" element={<Signin />} />
                <Route path="favourites" element={<Favourites />} />
                <Route path="shared" element={<Shared />} />
                <Route path="archived" element={<Archived />} />
                <Route path="deleted" element={<Deleted />} />
                <Route path="profile" element={<Profile />} />
                <Route path="folder" element={<FileTable />} />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route path="user-management" element={<Users />} />
              </Routes>
            </div>
          </div>
          <FileModal />
        </div>
      </div>
    </>
  );
};

export default BasePage;

export const extensionImageMap = {
  pdf: "assets/img/icons/pdf-02.svg",
  doc: "assets/img/icons/docs.svg",
  docx: "assets/img/icons/docs.svg",
  xls: "assets/img/icons/xls.svg",
  xlsx: "assets/img/icons/xls.svg",
  jpg: "assets/img/icons/image.svg",
  jpeg: "assets/img/icons/image.svg",
  png: "assets/img/icons/image.svg",
  webp: "assets/img/icons/webp.svg",
  folder: "assets/img/icons/folder.svg",
  mp4: "assets/img/icons/video.svg",
  mp3: "assets/img/icons/audio.svg",
  document: "assets/img/icons/unknown.svg",
  psd: "assets/img/icons/psd.svg",
  ppt: "assets/img/icons/ppt.svg",
  zip: "assets/img/icons/zip.svg",
  ai: "assets/img/icons/ai.svg",
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import {
  Edit,
  Star,
  Archive,
  Book,
  List,
  DownloadCloud,
  Eye,
  Trash2,
} from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { extensionImageMap } from "../../lib/types/extensions.tsx";

import { baseUrl } from "../../api/apiConfig";
import axios from "axios";
import { setupInterceptors } from "../../lib/interceptor.tsx";

//Providers
import { store } from "../../core/redux/store.jsx";
import { getUser } from "../../core/redux/slices/authSlice";
import { Details } from "./details.jsx";
import {
  resetCurrent,
  resetStack,
  setCurrent,
} from "../../core/redux/slices/folderSlice.jsx";
import { Toaster } from "react-hot-toast";

const MyFiles = () => {
  const options1 = [
    { value: "lastModified", label: "Last Modified" },
    { value: "lastModifiedByMe", label: "Last Modified by Me" },
    { value: "lastOpenedByMe", label: "Last Opened by Me" },
  ];

  const [activeStars, setActiveStars] = useState(false);

  const [folders, setFolders] = useState([]);
  const [folderDetails, setFolderDetails] = useState([]);

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const user = getUser(store.getState());

  const fetchFolders = () =>
    axiosInstance
      .get(`${baseUrl}/files`)
      .then((response) => {
        const data = response.data.data;
        setFolders(data);
      })
      .catch((error) => {
        console.log(error);
      });

  const deleteFolder = (file_id) => {
    axiosInstance
      .delete(`${baseUrl}/files/delete/${file_id}`)
      .then((response) => {
        console.log("deleted", response.data.data);
        fetchFolders();
      })
      .catch((error) => {
        console.log("Error deleting folder", error);
      });
  };

  useEffect(() => {
    store.dispatch(resetCurrent());
    store.dispatch(resetStack());
    fetchFolders();
  }, []);

  const handleFolderClick = (folderId) => {
    axiosInstance
      .get(`${baseUrl}/files/${folderId}`)
      .then((response) => {
        const folderDetails = response.data.data;
        setFolderDetails(folderDetails);
        console.log(folderDetails.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const view = (folder_id, user_duration) => {
    const action = "view";

    const payload = {
      action: action,
      duration: user_duration,
    };
    axiosInstance
      .post(`${baseUrl}/files/action/${folder_id}`, payload)
      .then((response) => {
        const fileUrl = response.data.data.url;

        window.open(fileUrl, "_blank");
        console.log("File url", response.data.data.url);
      })
      .catch((error) => {
        console.log("Error viewing folder", error);
      });
  };

  const archiveFolder = (folder_id) => {
    const action = "archive";

    const payload = {
      action: action,
    };
    axiosInstance
      .post(`${baseUrl}/files/action/${folder_id}`, payload)
      .then((response) => {
        fetchFolders();
      })
      .catch((error) => {
        console.log("Error archiving folder", error);
      });
  };

  const unArchiveFolder = (folder_id) => {
    const action = "unarchive";

    const payload = {
      action: action,
    };
    axiosInstance
      .post(`${baseUrl}/files/action/${folder_id}`, payload)
      .then((response) => {
        fetchFolders();
      })
      .catch((error) => {
        console.log("Error archiving folder", error);
      });
  };

  const handleStarClick = (index, folder_Id) => {
    const newActiveStars = {
      ...activeStars,
      [index]: !activeStars[index],
    };

    const action = newActiveStars[index] ? "favourite" : "unfavourite";

    const payload = {
      action: action,
    };

    axiosInstance
      .post(`${baseUrl}/files/action/${folder_Id}`, payload)
      .then((response) => {
        fetchFolders();
      })
      .catch((error) => {
        console.log("Error updating folder favorite status", error);
      });

    setActiveStars(newActiveStars);
  };

  const handleDownloadClick = (folder_Id, folder_name) => {
    axiosInstance
      .get(`${baseUrl}/files/${folder_Id}/download`, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", folder_name);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log("Error dowloading file ", error);
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="card p-4 bg-white all-files mb-0">
        <div className="seprator-lg d-lg-flex align-items-center justify-content-between">
          <h4>My Files</h4>
          <div className="d-sm-flex align-items-center btn-grp">
            <Link to="#" className="btn btn-primary me-2">
              <List className="feather-20" />
            </Link>
            {/* <Link to="#" className="btn btn-outline-secondary me-2">
              <Layout className="feather-20" />
            </Link>
            <Link to="#" className="btn btn-outline-secondary me-2">
              <Grid className="feather-20" />
            </Link> */}
            <div className="icon-select">
              {/* <span className="icon">
                <Calendar className="feather-16" />
              </span> */}
              <Select className="select" options={options1} />
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table datanew">
            <thead>
              <tr>
                <th>
                  <label className="checkboxs">
                    <input type="checkbox" id="select-all" />
                    <span className="checkmarks" />
                  </label>
                </th>
                <th>Name</th>
                <th>Last Modified</th>
                <th>Size</th>
                {/* <th>Owned Memebr</th> */}
                <th>Action</th>
              </tr>
            </thead>
            {folders.map((folder, index) => {
              let fileExtension = null;

              const fileNameParts = folder.name.split(".");
              if (fileNameParts.length > 1) {
                fileExtension = fileNameParts.pop().toLowerCase();
              }

              const imageSrc =
                folder.type === "folder"
                  ? extensionImageMap["folder"]
                  : extensionImageMap[fileExtension] ||
                    extensionImageMap["document"];

              return (
                <tbody key={index}>
                  <tr>
                    <td>
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks" />
                      </label>
                    </td>
                    <td className="productimgname">
                      <Link
                        to="#"
                        className="product-img d-flex align-items-center popup-toggle"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        onClick={() => handleFolderClick(folder.id)}
                      >
                        <ImageWithBasePath
                          src={imageSrc}
                          alt="Product"
                          className="me-2"
                        />
                        <span>{folder.name}</span>
                      </Link>
                    </td>
                    <td>
                      {folder.updated_at}
                      <br />
                      {folder.owner.name}
                    </td>
                    <td>{folder.size}</td>
                    <td className="text-end">
                      <div className="d-flex align-items-center">
                        <Link
                          to="#"
                          className="me-2"
                          onClick={() => handleStarClick(index, folder.id)}
                        >
                          <Star
                            className="feather-16 me-2 color-secondary"
                            color={
                              folder.favourite
                                ? "orange"
                                : activeStars[index]
                                ? "orange"
                                : "currentColor"
                            }
                            fill={
                              folder.favourite
                                ? "orange"
                                : activeStars[index]
                                ? "orange"
                                : "none"
                            }
                          />
                        </Link>
                        <div className="dropdown">
                          <Link
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className="dropset"
                          >
                            <i className="fa fa-ellipsis-v" />
                          </Link>
                          <ul className="dropdown-menu">
                            {folder.type === "folder" ? (
                              <li>
                                <Link
                                  to={`/dashboard/folder`}
                                  className="dropdown-item"
                                  onClick={() => {
                                    store.dispatch(setCurrent(folder.id));
                                  }}
                                >
                                  <Eye className="feather-14 me-2" />
                                  Open
                                </Link>
                              </li>
                            ) : (
                              <>
                                <li>
                                  <Link
                                    to="#"
                                    className="dropdown-item"
                                    onClick={() => view(folder.id, 7)}
                                  >
                                    <Eye className="feather-14 me-2" />
                                    Preview
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      handleDownloadClick(
                                        folder.id,
                                        folder.name
                                      )
                                    }
                                    className="dropdown-item"
                                  >
                                    <DownloadCloud className="feather-14 me-2" />
                                    Download
                                  </Link>
                                </li>
                              </>
                            )}
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item popup-toggle"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                                onClick={() => handleFolderClick(folder.id)}
                              >
                                <Book className="feather-14 me-2" />
                                Details
                              </Link>
                            </li>
                            {folder.state !== "archived" && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() => archiveFolder(folder.id)}
                                  className="dropdown-item"
                                >
                                  <Archive className="feather-14 me-2" />
                                  Archive
                                </Link>
                              </li>
                            )}
                            {folder.state === "archived" && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() => unArchiveFolder(folder.id)}
                                  className="dropdown-item"
                                >
                                  <Archive className="feather-14 me-2" />
                                  Unarchive
                                </Link>
                              </li>
                            )}

                            <li onClick={() => deleteFolder(folder.id)}>
                              <Link to="#" className="dropdown-item">
                                <Trash2 className="feather-14 me-2" />
                                Delete
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
      <Details filePreview={folderDetails} />
    </>
  );
};

export default MyFiles;

import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath.jsx";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { baseUrl } from "../../../api/apiConfig.js";
import axios from "axios";
import { setupInterceptors } from "../../../lib/interceptor.tsx";
import {
  setAuthToken,
  setAuthUser,
  setEntity,
  setUserStorage,
} from "../../../core/redux/slices/authSlice.jsx";
import { store } from "../../../core/redux/store.jsx";
import { Toaster } from "react-hot-toast";

const Signin = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paramCode = searchParams.get("code") ?? "";
  const paramUsername = searchParams.get("username") ?? "";

  const [loginDetails, setLoginDetails] = useState({
    email: paramUsername,
    password: "",
  });
  const [entity, setEntityInput] = useState(paramCode);
  const [loginError, setLoginError] = useState("");
  const [signing, setSigning] = useState("Sign In");

  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const handleLogin = async (e) => {
    e.preventDefault();
    setSigning("Signing In...");

    store.dispatch(setEntity(entity));

    try {
      const response = await axiosInstance.post(
        `${baseUrl}/account/login`,
        loginDetails,
        {
          headers: {
            "X-Entity": entity,
          },
        }
      );
      const tokenResponse = response.data.data.access_token;
      store.dispatch(setAuthToken(tokenResponse));
      store.dispatch(setAuthUser(response.data.data.user));
      store.dispatch(setUserStorage(response.data.data.storage));

      if (tokenResponse) {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Login error:", error);

      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      setLoginError(errorMessage);
    } finally {
      setSigning("Sign In");
    }
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper login-new">
            <div className="container">
              <div className="login-content user-login">
                <div className="login-logo">
                  <ImageWithBasePath src="assets/img/logo.png" />
                  <Link to="/" className="login-logo logo-white">
                    <ImageWithBasePath src="assets/img/logo-white.png" />
                  </Link>
                </div>
                <form onSubmit={handleLogin}>
                  <div className="login-userset">
                    <div className="login-userheading">
                      <h3>Sign In</h3>
                      <h4>
                        Access the SME Vault panel using your email and
                        passcode.
                      </h4>
                    </div>
                    <div className="form-login">
                      <label className="form-label">Email Address</label>
                      <div className="form-addons">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={loginDetails.email}
                          onChange={(e) =>
                            setLoginDetails({
                              ...loginDetails,
                              email: e.target.value,
                            })
                          }
                        />
                        <ImageWithBasePath
                          src="assets/img/icons/mail.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="form-login">
                      <label>Password</label>
                      <div className="pass-group">
                        <input
                          type={
                            passwordVisibility.password ? "text" : "password"
                          }
                          className="pass-input"
                          name="password"
                          value={loginDetails.password}
                          onChange={(e) =>
                            setLoginDetails({
                              ...loginDetails,
                              password: e.target.value,
                            })
                          }
                        />
                        <span
                          className={`fas toggle-password ${
                            passwordVisibility.password
                              ? "fa-eye"
                              : "fa-eye-slash"
                          }`}
                          onClick={() => togglePasswordVisibility("password")}
                        />
                      </div>
                    </div>
                    <div className="form-login">
                      <label className="form-label">Entity Code</label>
                      <div className="form-addons">
                        <input
                          type="text"
                          className="form-control"
                          name="entityCode"
                          value={entity}
                          placeholder="eg. demo"
                          onChange={(e) => setEntityInput(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-login authentication-check">
                      <div className="row">
                        <div className="col-6">
                          <div className="custom-control custom-checkbox"></div>
                        </div>
                        <div className="col-6 text-end">
                          <Link
                            className="forgot-link"
                            to={`/forgot-password?code=${entity}&username=${encodeURIComponent(
                              loginDetails.email
                            )}`}
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="form-login">
                      <button className="btn btn-login" type="submit">
                        {signing === "Sign In" ? "Sign In" : "Signing In..."}
                      </button>
                    </div>
                    <div className="signinform"></div>
                  </div>
                </form>
                {loginError && (
                  <div className="alert alert-danger mt-3">{loginError}</div>
                )}
              </div>
              <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                <p>Copyright © {currentYear} SME Cloud. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import ImageWithBasePath from "../../core/img/imagewithbasebath.jsx";
import { baseUrl } from "../../api/apiConfig.js";
import axios from "axios";
import { setupInterceptors } from "../../lib/interceptor.tsx";
import { extensionImageMap } from "../../lib/types/extensions.tsx";
import { List } from "react-feather";
import { Toaster } from "react-hot-toast";

import { store } from "../../core/redux/store.jsx";
import {
  resetCurrent,
  resetStack,
} from "../../core/redux/slices/folderSlice.jsx";

const Shared = () => {
  const options1 = [
    { value: "lastModified", label: "Last Modified" },
    { value: "lastModifiedByMe", label: "Last Modified by Me" },
    { value: "lastOpenedByMe", label: "Last Opened by Me" },
  ];

  const options2 = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "sortByRelevance", label: "Sort By Relevance" },
    { value: "sortBySize", label: "Sort By Size" },
    { value: "orderAscending", label: "Order Ascending" },
    { value: "orderDescending", label: "Order Descending" },
    { value: "uploadTime", label: "Upload Time" },
  ];

  const options3 = [
    { value: "recent", label: "Recent" },
    { value: "lastWeek", label: "Last Week" },
    { value: "lastMonth", label: "Last Month" },
  ];

  const options4 = [
    { value: "allFileTypes", label: "All File types" },
    { value: "folders", label: "Folders" },
    { value: "pdf", label: "PDF" },
    { value: "images", label: "Images" },
    { value: "videos", label: "Videos" },
    { value: "audios", label: "Audios" },
    { value: "excel", label: "Excel" },
  ];

  const [files, setFiles] = useState([]);
  const [isOpen, setOpen] = useState(false);

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const fetchSharedFiles = () =>
    axiosInstance
      .get(`${baseUrl}/files`, { params: { state: "shared" } })
      .then((response) => {
        const data = response.data.data;
        setFiles(data);
      })
      .catch((error) => {
        console.log(error);
      });

  useEffect(() => {
    store.dispatch(resetCurrent());
    store.dispatch(resetStack());
    fetchSharedFiles();
  }, []);

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={` file-manager `}>
        <div className="card p-4 bg-white all-files mb-0">
          <div className="seprator-lg d-lg-flex align-items-center justify-content-between">
            <h4>Files Shared With Me</h4>
            <div className="d-sm-flex align-items-center btn-grp">
              <Link to="#" className="btn btn-primary me-2">
                <List className="feather-20" />
              </Link>
              {/* <Link to="#" className="btn btn-outline-secondary me-2">
                <Layout className="feather-20" />
              </Link>
              <Link to="#" className="btn btn-outline-secondary me-2">
                <Grid className="feather-20" />
              </Link> */}
              <div className="icon-select">
                <Select className="select" options={options1} />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table datanew">
              <thead>
                <tr>
                  <th>
                    <label className="checkboxs">
                      <input type="checkbox" id="select-all" />
                      <span className="checkmarks" />
                    </label>
                  </th>
                  <th>Name</th>
                  <th>Last Modified</th>
                  <th>Size</th>
                </tr>
              </thead>
              {files.map((folder, index) => {
                let fileExtension = null;

                const fileNameParts = folder.name.split(".");
                if (fileNameParts.length > 1) {
                  fileExtension = fileNameParts.pop().toLowerCase();
                }

                const imageSrc =
                  folder.type === "folder"
                    ? extensionImageMap["folder"]
                    : extensionImageMap[fileExtension] ||
                      extensionImageMap["document"];
                return (
                  <tbody key={index}>
                    <tr>
                      <td>
                        <label className="checkboxs">
                          <input type="checkbox" />
                          <span className="checkmarks" />
                        </label>
                      </td>
                      <td className="productimgname">
                        <Link
                          to="product-list"
                          className="product-img d-flex align-items-center"
                        >
                          <ImageWithBasePath
                            src={imageSrc}
                            alt="Product"
                            className="me-2"
                          />
                          <span>{folder.name}</span>
                        </Link>
                      </td>
                      <td>
                        {folder.updated_at}
                        <br />
                        {folder.owner.name}
                      </td>
                      <td>{folder.size}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shared;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Edit,
  List,
  Star,
  Trash2,
  Eye,
  Move,
  Book,
  DownloadCloud,
  Copy,
  ArrowLeftCircle,
} from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import Select from "react-select";
import { extensionImageMap } from "../../lib/types/extensions.tsx";

import {
  getCurrent,
  getStack,
  popStack,
  setCurrent,
  setStack,
  getUploads,
} from "../../core/redux/slices/folderSlice.jsx";

import { baseUrl } from "../../api/apiConfig";
import axios from "axios";
import { setupInterceptors } from "../../lib/interceptor.tsx";
import { Details } from "./details.jsx";
import { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";

const FileTable = () => {
  const dispatch = useDispatch();
  const reloadTrigger = useSelector(getUploads);

  const [activeStars, setActiveStars] = useState(false);
  const current = useSelector(getCurrent);
  const stack = useSelector(getStack);

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const options1 = [
    { value: "lastModified", label: "Last Modified" },
    { value: "lastModifiedByMe", label: "Last Modified by Me" },
    { value: "lastOpenedByMe", label: "Last Opened by Me" },
  ];

  const [files, setFiles] = useState([]);
  const [folder, setFolder] = useState([]);
  const [folderDetails, setFolderDetails] = useState([]);
  const [localFormData, setLocalFormData] = useState({
    name: "",
  });
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedFolderName, setSelectedFolderName] = useState({
    name: "",
    id: "",
  });
  const [allFolders, setAllFolders] = useState([]);

  const fetchFiles = () => {
    axiosInstance
      .get(`${baseUrl}/files/${current}`)
      .then((response) => {
        const dataFolder = response.data.data;
        const data = response.data.data.content;
        const dataFiles = response.data.data.subfolders;
        setFolder(dataFolder);
        setFiles(data);
        setAllFolders(dataFiles);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchFiles();
  }, [current, reloadTrigger]);

  const handleStarClick = (index, folder_Id) => {
    const newActiveStars = {
      ...activeStars,
      [index]: !activeStars[index],
    };

    const action = newActiveStars[index] ? "favourite" : "unfavourite";

    const payload = {
      action: action,
    };

    axiosInstance
      .post(`${baseUrl}/files/action/${folder_Id}`, payload)
      .then((response) => {
        fetchFiles();
      })
      .catch((error) => {
        console.log("Error updating folder favorite status", error);
      });

    setActiveStars(newActiveStars);
  };

  const deleteFolder = (file_id) => {
    axiosInstance
      .delete(`${baseUrl}/files/delete/${file_id}`)
      .then((response) => {
        fetchFiles();
      })
      .catch((error) => {
        console.log("Error deleting folder", error);
      });
  };

  const view = (folder_id, user_duration) => {
    const action = "view";

    const payload = {
      action: action,
      duration: user_duration,
    };
    axiosInstance
      .post(`${baseUrl}/files/action/${folder_id}`, payload)
      .then((response) => {
        const fileUrl = response.data.data.url;

        window.open(fileUrl, "_blank");
      })
      .catch((error) => {
        console.log("Error viewing folder", error);
      });
  };

  const moveFile = () => {
    const { newFolderId } = localFormData;

    if (!selectedFileId || !newFolderId) {
      console.error("File ID or New Folder ID is missing");
      return;
    }

    const payload = {
      action: "move",
      file_id: selectedFileId,
      new_folder_id: newFolderId,
    };

    axiosInstance
      .post(`${baseUrl}/files/move`, payload)
      .then((response) => {
        fetchFiles();
      })
      .catch((error) => {
        console.log("Error moving file", error);
      });
  };

  const copyFile = () => {
    const { newFolderId } = localFormData;

    if (!selectedFileId || !newFolderId) {
      console.error("File ID or New Folder ID is missing");
      return;
    }

    const payload = {
      action: "copy",
      file_id: selectedFileId,
      new_folder_id: newFolderId,
    };

    axiosInstance
      .post(`${baseUrl}/files/move`, payload)
      .then((response) => {
        fetchFiles();
      })
      .catch((error) => {
        console.log("Error moving file", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData({ ...localFormData, [name]: value });
  };

  const updateFolder = () => {
    if (!selectedFolderId) {
      console.error("Selected folder ID is not defined");
      return;
    }

    if (localFormData.name.trim() === "") {
      console.error("Folder name cannot be empty");
      return;
    }

    axiosInstance
      .put(`${baseUrl}/files/${selectedFolderId}`, localFormData)
      .then((response) => {
        console.log("Folder updated successfully");
        fetchFiles();
      })
      .catch((error) => {
        console.log("Update failed:", error);
      });
  };

  const handleRenameClick = (folderId, folderName) => {
    setSelectedFolderId(folderId);
    setSelectedFolderName(folderName);
    setLocalFormData({ ...localFormData, name: folderName });
  };

  const handleFolderClick = (folderId) => {
    axiosInstance
      .get(`${baseUrl}/files/${folderId}`)
      .then((response) => {
        const folderDetails = response.data.data;
        setFolderDetails(folderDetails);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMoveItemClick = (fileId) => {
    setSelectedFileId(fileId);
  };

  const handleDownloadClick = (folder_Id, folder_name) => {
    axiosInstance
      .get(`${baseUrl}/files/${folder_Id}/download`, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", folder_name);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log("Error dowloading file ", error);
      });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="card p-4 bg-white all-files mb-0" style={{ zIndex: 1 }}>
        <div className="seprator-lg d-lg-flex align-items-center justify-content-between">
          <Link
            to={stack.length > 0 ? "#" : "/dashboard"}
            className="btn btn-secondary me-2"
          >
            <ArrowLeftCircle
              className="feather-20"
              onClick={() => {
                if (stack.length > 0) {
                  dispatch(popStack());
                }
              }}
            />
          </Link>
          <h4>{folder.name}</h4>
          <div className="d-sm-flex align-items-center btn-grp">
            <Link to="#" className="btn btn-primary me-2">
              <List className="feather-20" />
            </Link>
            <div className="icon-select">
              <Select className="select" options={options1} />
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table datanew">
            <thead>
              <tr>
                <th>
                  <label className="checkboxs">
                    <input type="checkbox" id="select-all" />
                    <span className="checkmarks" />
                  </label>
                </th>
                <th>Name</th>
                <th>Last Modified</th>
                <th>Size</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => {
                let fileExtension = null;

                const fileNameParts = file.meta.extension;

                if (fileNameParts != undefined) {
                  fileExtension = fileNameParts.toLowerCase();
                }

                const imageSrc =
                  file.type === "folder"
                    ? extensionImageMap["folder"]
                    : extensionImageMap[fileExtension] ||
                      extensionImageMap["document"];

                return (
                  <tr key={index}>
                    <td>
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks" />
                      </label>
                    </td>
                    <td className="productimgname">
                      <Link
                        to="#"
                        className="popup-toggle product-img d-flex align-items-center"
                        onClick={() => view(file.id, 7)}
                      >
                        <ImageWithBasePath
                          src={imageSrc}
                          alt={file.name}
                          className="me-2"
                        />
                        <span>{file.name}</span>
                      </Link>
                    </td>
                    <td>
                      {file.updated_at}
                      <br />
                      {file.owner.name}
                    </td>
                    <td>{file.size}</td>
                    <td className="text-end">
                      <div className="d-flex align-items-center">
                        <Link
                          to="#"
                          className="me-2"
                          onClick={() => handleStarClick(index, file.id)}
                        >
                          <Star
                            className="feather-16 me-2 color-secondary"
                            color={
                              file.favourite
                                ? "orange"
                                : activeStars[index]
                                ? "orange"
                                : "currentColor"
                            }
                            fill={
                              file.favourite
                                ? "orange"
                                : activeStars[index]
                                ? "orange"
                                : "none"
                            }
                          />
                        </Link>
                        <div className="dropdown">
                          <Link
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className="dropset"
                          >
                            <i className="fa fa-ellipsis-v" />
                          </Link>
                          <ul className="dropdown-menu">
                            {file.type !== "folder" && (
                              <li>
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  onClick={() => view(file.id, 7)}
                                >
                                  <Eye className="feather-14 me-2" />
                                  Preview
                                </Link>
                              </li>
                            )}

                            {file.type === "folder" && (
                              <li>
                                <Link
                                  onClick={() => {
                                    dispatch(setStack(current));
                                    dispatch(setCurrent(file.id));
                                  }}
                                  className="dropdown-item"
                                >
                                  <Eye className="feather-14 me-2" />
                                  Open
                                </Link>
                              </li>
                            )}

                            <li>
                              <Link
                                to="#"
                                className="dropdown-item popup-toggle product-img d-flex align-items-center"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                                onClick={() => handleFolderClick(file.id)}
                              >
                                <Book className="feather-14 me-2" />
                                Details
                              </Link>
                            </li>
                            <li
                              onClick={() =>
                                handleRenameClick(file.id, file.name)
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#update-folder"
                            >
                              <Link to="#" className="dropdown-item">
                                <Edit className="feather-14 me-2" />
                                Rename
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  deleteFolder(file.id);
                                }}
                              >
                                <Trash2 className="feather-14 me-2" />
                                Delete
                              </Link>
                            </li>
                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#copy-file"
                              onClick={() => handleMoveItemClick(file.id)}
                            >
                              <Link to="#" className="dropdown-item">
                                <Copy className="feather-14 me-2" />
                                Copy
                              </Link>
                            </li>
                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#move-file"
                              onClick={() => handleMoveItemClick(file.id)}
                            >
                              <Link to="#" className="dropdown-item">
                                <Move className="feather-14 me-2" />
                                Move
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={() =>
                                  handleDownloadClick(file.id, file.name)
                                }
                                className="dropdown-item"
                              >
                                <DownloadCloud className="feather-14 me-2" />
                                Download
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Details filePreview={folderDetails} />

      {/* Update Folder */}
      <div
        className="modal fade modal-default pos-modal"
        id="update-folder"
        aria-labelledby="upload-folder"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-4 d-flex align-items-center justify-content-between">
              <h5>Rename Folder</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-12">
                  <label className="form-label">Folder Name</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={handleInputChange}
                    value={localFormData.name}
                  />
                </div>
              </div>
              <div className="modal-footer d-sm-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={updateFolder}
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Update Folder */}

      {/* Move File */}
      <div
        className="modal fade modal-default pos-modal"
        id="move-file"
        aria-labelledby="move-file"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-4 d-flex align-items-center justify-content-between">
              <h5>Move File</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-12">
                  <label className="form-label">
                    Select Destination Folder
                  </label>
                  <select
                    name="newFolderId"
                    className="form-select"
                    onChange={handleInputChange}
                    value={localFormData.newFolderId}
                  >
                    <option value="">Select Folder</option>
                    {allFolders
                      .filter((folder) => folder.type === "folder")
                      .map((folder) => (
                        <option key={folder.id} value={folder.id}>
                          {folder.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="modal-footer d-sm-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={moveFile}
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Move
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Move File */}

      {/* Copy File */}
      <div
        className="modal fade modal-default pos-modal"
        id="copy-file"
        aria-labelledby="copy-file"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-4 d-flex align-items-center justify-content-between">
              <h5>Copy File</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-12">
                  <label className="form-label">
                    Select Destination Folder
                  </label>
                  <select
                    name="newFolderId"
                    className="form-select"
                    onChange={handleInputChange}
                    value={localFormData.newFolderId}
                  >
                    <option value="">Select Folder</option>
                    {allFolders
                      .filter((folder) => folder.type === "folder")
                      .map((folder) => (
                        <option key={folder.id} value={folder.id}>
                          {folder.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="modal-footer d-sm-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={copyFile}
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Copy File */}
    </>
  );
};

export default FileTable;

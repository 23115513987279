import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import {
  Edit,
  List,
  Star,
  Trash2,
  Eye,
  Move,
  Book,
  Copy,
  Archive,
  DownloadCloud,
  Share,
} from "react-feather";
import ImageWithBasePath from "../../core/img/imagewithbasebath";

import { baseUrl } from "../../api/apiConfig";
import axios from "axios";
import { setupInterceptors } from "../../lib/interceptor.tsx";
import FileModal from "../../core/modals/applications/fileModal.jsx";
import { Details } from "../filemanager/details.jsx";

import toast, { Toaster } from "react-hot-toast";
import Scrollbars from "react-custom-scrollbars-2";
//Providers
import { store } from "../../core/redux/store.jsx";
import {
  resetCurrent,
  resetStack,
  resetUploads,
  setCurrent,
} from "../../core/redux/slices/folderSlice.jsx";
import { extensionImageMap } from "../../lib/types/extensions.tsx";

const FileContent = () => {
  const options1 = [
    { value: "lastModified", label: "Last Modified" },
    { value: "lastModifiedByMe", label: "Last Modified by Me" },
    { value: "lastOpenedByMe", label: "Last Opened by Me" },
  ];

  const [folders, setFolders] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [folderDetails, setFolderDetails] = useState([]);
  const [overview, setOverview] = useState({});
  const [activeStars, setActiveStars] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [duration, setDuration] = useState(1);
  const [mode, setMode] = useState("external");
  const [selectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const fetchOverview = () => {
    axiosInstance
      .get(`${baseUrl}/files/overview`)
      .then((response) => {
        const data = response.data.data;
        const dataFolders = response.data.data.folders;
        const dataFiles = response.data.data.all;
        setOverview(data);
        setFolders(dataFolders);
        setAllFolders(dataFiles);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteFolder = (file_id) => {
    axiosInstance
      .delete(`${baseUrl}/files/delete/${file_id}`)
      .then((response) => {
        fetchOverview();
      })
      .catch((error) => {
        console.log("Error deleting folder", error);
      });
  };

  useEffect(() => {
    store.dispatch(resetCurrent());
    store.dispatch(resetStack());
    store.dispatch(resetUploads());
    fetchOverview();
  }, []);

  const handleStarClick = (index, folder_Id) => {
    const newActiveStars = {
      ...activeStars,
      [index]: !activeStars[index],
    };

    const action = newActiveStars[index] ? "favourite" : "unfavourite";

    const payload = {
      action: action,
    };

    axiosInstance
      .post(`${baseUrl}/files/action/${folder_Id}`, payload)
      .then((response) => {
        fetchOverview();
      })
      .catch((error) => {
        console.log("Error updating folder favorite status", error);
      });

    setActiveStars(newActiveStars);
  };

  const handleFolderClick = (folderId) => {
    axiosInstance
      .get(`${baseUrl}/files/${folderId}`)
      .then((response) => {
        const folderDetails = response.data.data;
        setFolderDetails(folderDetails);
        console.log(folderDetails.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const shareFolder = () => {
    const action = "view";
    const payload = {
      action: action,
      duration: duration,
    };

    axiosInstance
      .post(`${baseUrl}/files/action/${selectedFolderId}`, payload)
      .then((response) => {
        const fileUrl = response.data.data.url;

        setShareUrl(fileUrl);
        setDuration("");
      })
      .catch((error) => {
        console.log("Error sharing", error);
      });
  };

  const fetchUsers = () => {
    axiosInstance
      .get(`${baseUrl}/users`)
      .then((response) => {
        const allUsers = response.data.data;

        setUsers(allUsers);
      })
      .catch((error) => {
        console.log("Error fetching users", error);
      });
  };

  const archiveFolder = (folder_id) => {
    const action = "archive";

    const payload = {
      action: action,
    };
    axiosInstance
      .post(`${baseUrl}/files/action/${folder_id}`, payload)
      .then((response) => {
        fetchFolders();
      })
      .catch((error) => {
        console.log("Error archiving folder", error);
      });
  };

  const unArchiveFolder = (folder_id) => {
    const action = "unarchive";

    const payload = {
      action: action,
    };
    axiosInstance
      .post(`${baseUrl}/files/action/${folder_id}`, payload)
      .then((response) => {
        fetchFolders();
      })
      .catch((error) => {
        console.log("Error archiving folder", error);
      });
  };

  const handleDownloadClick = (folder_Id, folder_name) => {
    axiosInstance
      .get(`${baseUrl}/files/${folder_Id}/download`, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", folder_name);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log("Error dowloading file ", error);
      });
  };

  const view = (folder_id, user_duration) => {
    const action = "view";

    const payload = {
      action: action,
      duration: user_duration,
    };
    axiosInstance
      .post(`${baseUrl}/files/action/${folder_id}`, payload)
      .then((response) => {
        const fileUrl = response.data.data.url;

        window.open(fileUrl, "_blank");
        console.log("File url", response.data.data.url);
      })
      .catch((error) => {
        console.log("Error viewing folder", error);
      });
  };

  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedFolderName, setSelectedFolderName] = useState({
    name: "",
    id: "",
  });
  const [localFormData, setLocalFormData] = useState({
    name: "",
  });

  const updateFolder = () => {
    if (!selectedFolderId) {
      console.error("Selected folder ID is not defined");
      return;
    }

    if (localFormData.name.trim() === "") {
      console.error("Folder name cannot be empty");
      return;
    }

    axiosInstance
      .put(`${baseUrl}/files/${selectedFolderId}`, localFormData)
      .then((response) => {
        fetchOverview();
      })
      .catch((error) => {
        console.log("Update failed:", error);
      });
  };

  const handleRenameClick = (folderId, folderName) => {
    setSelectedFolderId(folderId);
    setSelectedFolderName(folderName);
    setLocalFormData({ ...localFormData, name: folderName });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData({ ...localFormData, [name]: value });
  };

  const moveFile = () => {
    const { newFolderId } = localFormData;

    if (!selectedFileId || !newFolderId) {
      console.error("File ID or New Folder ID is missing");
      return;
    }

    const payload = {
      action: "move",
      file_id: selectedFileId,
      new_folder_id: newFolderId,
    };

    axiosInstance
      .post(`${baseUrl}/files/move`, payload)
      .then((response) => {
        fetchOverview();
      })
      .catch((error) => {
        console.log("Error moving file", error);
      });
  };

  const copyFile = () => {
    const { newFolderId } = localFormData;

    if (!selectedFileId || !newFolderId) {
      console.error("File ID or New Folder ID is missing");
      return;
    }

    const payload = {
      action: "copy",
      file_id: selectedFileId,
      new_folder_id: newFolderId,
    };

    console.log(payload);

    axiosInstance
      .post(`${baseUrl}/files/move`, payload)
      .then((response) => {
        fetchOverview();
      })
      .catch((error) => {
        console.log("Error moving file", error);
      });
  };

  const handleMoveItemClick = (fileId) => {
    setSelectedFileId(fileId);
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
    if (e.target.value === "Internal") {
      fetchUsers();
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(
      () => {
        setDuration("");
        setShareUrl("");
        toast.success("Copied to clipboard");
      },
      (err) => {
        toast.success("Error copying to clipboard: ", err);
      }
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      {/* /Overview */}
      <div className="overview seprator-lg px-3">
        <h4 className="mb-2">Overview</h4>
        <div className="row g-3">
          <div className="col-sm-6 col-md-3">
            <div className="detail">
              <Link
                to="myfiles"
                className="d-flex align-items-center justify-content-center bg-light-orange bg p-4"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <ImageWithBasePath
                    src="assets/img/icons/folder.svg"
                    alt="Folder"
                  />
                </span>
              </Link>
              <div className="d-flex align-items-center justify-content-between info">
                <h6>
                  <Link to="#">Folders</Link>
                </h6>
                <span>{overview.folder} folders</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="detail">
              <Link
                to="myfiles"
                className="d-flex align-items-center justify-content-center bg-light-red bg p-4"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <ImageWithBasePath
                    src="assets/img/icons/file.svg"
                    alt="File"
                  />
                </span>
              </Link>
              <div className="d-flex align-items-center justify-content-between info">
                <h6>
                  <Link to="#">Files</Link>
                </h6>
                <span>{overview.document} Files</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="detail">
              <Link
                to="myfiles"
                className="d-flex align-items-center justify-content-center bg-light-green bg p-4"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <ImageWithBasePath
                    src="assets/img/icons/image.svg"
                    alt="Folder"
                  />
                </span>
              </Link>
              <div className="d-flex align-items-center justify-content-between info">
                <h6>
                  <Link to="#">Images</Link>
                </h6>
                <span>{overview.image} Files</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="detail">
              <Link
                to="myfiles"
                className="d-flex align-items-center justify-content-center bg-light-red bg p-4"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <ImageWithBasePath
                    src="assets/img/icons/video.svg"
                    alt="Folder"
                  />
                </span>
              </Link>
              <div className="d-flex align-items-center justify-content-between info">
                <h6>
                  <Link to="#">Videos</Link>
                </h6>
                <span>{overview.video} Files</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Overview */}

      {/* Accordian */}
      <div
        className="accordion seprator-lg"
        id="accordionPanelsStayOpenExample"
      >
        <div
          className="accordion-item"
          style={{
            width: "100%",
            height: "240px",
            zIndex: 10,
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <Scrollbars
            autoHide={true}
            autoHideTimeout={1000}
            autoHideDuration={200}
            renderThumbHorizontal={(props) => (
              <div {...props} style={{ backgroundColor: "gray" }} />
            )}
          >
            <h4
              className="accordion-header d-flex align-items-center justify-content-between"
              id="panelsStayOpen-headingOne"
            >
              Folders
              <Link
                to="#"
                className="accordion-button a-auto"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              />
            </h4>

            <div
              id="panelsStayOpen-collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div className="accordion-body my-3">
                <div className="folders-carousel owl-theme d-flex">
                  {folders.map((folder, index) => (
                    <div className="folders p-3 me-3" key={index}>
                      <div className="d-flex align-items-center justify-content-between head">
                        <div className="d-flex align-items-center ">
                          <ImageWithBasePath
                            src="assets/img/icons/folder.svg"
                            alt="Folder"
                            className="me-2"
                          />
                          <h6
                            className="popup-toggle"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            onClick={() => handleFolderClick(folder.id)}
                          >
                            <Link to="#" className="text-wrap">
                              {folder.name}
                            </Link>
                          </h6>
                        </div>
                        <div className="dropdown">
                          <Link
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className="dropset"
                          >
                            <i className="fa fa-ellipsis-v" />
                          </Link>
                          <ul
                            className="dropdown-menu"
                            style={{ zIndex: 100, overflowY: "visible" }}
                          >
                            {/* <li
                            onClick={() => {
                              console.log(folder.id);
                            }}
                          >
                            <Link to="#" className="dropdown-item">
                              Share
                            </Link>
                          </li> */}
                            <li>
                              <Link
                                to={`folder`}
                                className="dropdown-item"
                                onClick={() => {
                                  store.dispatch(setCurrent(folder.id));
                                }}
                              >
                                Open
                              </Link>
                            </li>

                            <li
                              onClick={() =>
                                handleRenameClick(folder.id, folder.name)
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#update-folder"
                            >
                              <Link to="#" className="dropdown-item">
                                Rename
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  deleteFolder(folder.id.folder.name);
                                }}
                              >
                                Delete
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
      <Details filePreview={folderDetails} />

      <div className="px-3">
        {/* /Accordian */}
        <div className="card p-4 bg-white all-files mb-0">
          <div className="seprator-lg d-lg-flex align-items-center justify-content-between">
            <h4>All Files</h4>
            <div className="d-sm-flex align-items-center btn-grp">
              <Link to="#" className="btn btn-primary me-2">
                <List className="feather-20" />
              </Link>

              <div className="icon-select">
                <Select className="select" options={options1} />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table datanew">
              <thead>
                <tr>
                  <th>
                    <label className="checkboxs">
                      <input type="checkbox" id="select-all" />
                      <span className="checkmarks" />
                    </label>
                  </th>
                  <th>Name</th>
                  <th>Last Modified</th>
                  <th>Size</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allFolders.map((folder, index) => {
                  let fileExtension = null;

                  const fileNameParts = folder.name.split(".");
                  if (fileNameParts.length > 1) {
                    fileExtension = fileNameParts.pop().toLowerCase();
                  }

                  const imageSrc =
                    folder.type === "folder"
                      ? extensionImageMap["folder"]
                      : extensionImageMap[fileExtension] ||
                        extensionImageMap["document"];

                  return (
                    <tr key={index}>
                      <td>
                        <label className="checkboxs">
                          <input type="checkbox" />
                          <span className="checkmarks" />
                        </label>
                      </td>
                      <td className="productimgname">
                        {folder.type === "folder" ? (
                          <Link
                            to={`folder`}
                            className="popup-toggle product-img d-flex align-items-center"
                            onClick={() => {
                              store.dispatch(setCurrent(folder.id));
                            }}
                          >
                            <ImageWithBasePath
                              src={imageSrc}
                              alt={folder.name}
                              className="me-2"
                            />
                            <span>{folder.name}</span>
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            className="popup-toggle product-img d-flex align-items-center"
                            onClick={() => view(folder.id, 7)}
                          >
                            <ImageWithBasePath
                              src={imageSrc}
                              alt={folder.name}
                              className="me-2"
                            />
                            <span>{folder.name}</span>
                          </Link>
                        )}
                      </td>
                      <td>
                        {folder.updated_at}
                        <br />
                        {folder.owner.name}
                      </td>
                      <td>{folder.size}</td>
                      <td className="text-end">
                        <div className="d-flex align-items-center">
                          <Link
                            to="#"
                            className="me-2"
                            onClick={() => handleStarClick(index, folder.id)}
                          >
                            <Star
                              className="feather-16 me-2 color-secondary"
                              color={
                                folder.favourite
                                  ? "orange"
                                  : activeStars[index]
                                  ? "orange"
                                  : "currentColor"
                              }
                              fill={
                                folder.favourite
                                  ? "orange"
                                  : activeStars[index]
                                  ? "orange"
                                  : "none"
                              }
                            />
                          </Link>
                          <div className="dropdown">
                            <Link
                              to="#"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              className="dropset"
                            >
                              <i className="fa fa-ellipsis-v" />
                            </Link>
                            <ul className="dropdown-menu">
                              {folder.type === "folder" ? (
                                <li>
                                  <Link
                                    to={`folder`}
                                    className="dropdown-item"
                                    onClick={() => {
                                      store.dispatch(setCurrent(folder.id));
                                    }}
                                  >
                                    <Eye className="feather-14 me-2" />
                                    Open
                                  </Link>
                                </li>
                              ) : (
                                <li>
                                  <Link
                                    to="#"
                                    className="dropdown-item"
                                    onClick={() => view(folder.id, 7)}
                                  >
                                    <Eye className="feather-14 me-2" />
                                    Preview
                                  </Link>
                                </li>
                              )}
                              <li>
                                <Link
                                  to="#"
                                  className="dropdown-item popup-toggle product-img d-flex align-items-center"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#offcanvasRight"
                                  aria-controls="offcanvasRight"
                                  onClick={() => handleFolderClick(folder.id)}
                                >
                                  <Book className="feather-14 me-2" />
                                  Details
                                </Link>
                              </li>
                              {folder.type !== "folder" && (
                                <li
                                  data-bs-toggle="modal"
                                  data-bs-target="#share-folder"
                                  onClick={() => setSelectedFolderId(folder.id)}
                                >
                                  <Link to="#" className="dropdown-item">
                                    <Share className="feather-14 me-2" />
                                    Share
                                  </Link>
                                </li>
                              )}
                              <li
                                onClick={() =>
                                  handleRenameClick(folder.id, folder.name)
                                }
                                data-bs-toggle="modal"
                                data-bs-target="#update-folder"
                              >
                                <Link to="#" className="dropdown-item">
                                  <Edit className="feather-14 me-2" />
                                  Rename
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() => {
                                    deleteFolder(folder.id);
                                  }}
                                >
                                  <Trash2 className="feather-14 me-2" />
                                  Delete
                                </Link>
                              </li>
                              <li
                                data-bs-toggle="modal"
                                data-bs-target="#copy-file"
                                onClick={() => handleMoveItemClick(folder.id)}
                              >
                                <Link to="#" className="dropdown-item">
                                  <Copy className="feather-14 me-2" />
                                  Copy
                                </Link>
                              </li>
                              <li
                                data-bs-toggle="modal"
                                data-bs-target="#move-file"
                                onClick={() => handleMoveItemClick(folder.id)}
                              >
                                <Link to="#" className="dropdown-item">
                                  <Move className="feather-14 me-2" />
                                  Move
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handleDownloadClick(folder.id, folder.name)
                                  }
                                  className="dropdown-item"
                                >
                                  <DownloadCloud className="feather-14 me-2" />
                                  Download
                                </Link>
                              </li>
                              {folder.state === "archived" && (
                                <li>
                                  <Link
                                    to="#"
                                    onClick={() => unArchiveFolder(folder.id)}
                                    className="dropdown-item"
                                  >
                                    <Archive className="feather-14 me-2" />
                                    Unarchive
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {/* FileModal usage */}
        {selectedFile && (
          <FileModal
            fileId={selectedFile.id}
            currentName={selectedFile.name}
            onRename={onRename}
            onClose={() => setSelectedFile(null)}
          />
        )}
        {/* Update Folder */}
        <div
          className="modal fade modal-default pos-modal"
          id="update-folder"
          aria-labelledby="upload-folder"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header p-4 d-flex align-items-center justify-content-between">
                <h5>Rename</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body p-4">
                <div className="row">
                  <div className="col-12">
                    <label className="form-label">Name</label>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={handleInputChange}
                      value={localFormData.name}
                    />
                  </div>
                </div>
                <div className="modal-footer d-sm-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={updateFolder}
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Rename
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Update Folder */}
        {/* Share Folder */}
        <div
          className="modal fade modal-default pos-modal"
          id="share-folder"
          aria-labelledby="share-folder"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header p-4 d-flex align-items-center justify-content-between">
                <h5>Share</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body p-4">
                <div className="row">
                  <div className="col-12 mb-2">
                    <label className="form-label">Mode</label>
                    <select
                      className="form-select"
                      value={mode}
                      onChange={handleModeChange}
                    >
                      <option value={"external"}>External</option>
                      <option value={"internal"}>Internal</option>
                    </select>
                  </div>

                  {mode === "internal" && (
                    <div className="col-12 mb-2">
                      <label className="form-label">Select User</label>
                      <select
                        className="form-select"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                      >
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {mode === "external" && (
                    <div className="col-12 mb-2">
                      <label className="form-label">Duration (Days)</label>
                      <input
                        name="duration"
                        type="number"
                        className="form-control"
                        max={7}
                        min={1}
                        value={duration}
                        onChange={(e) => setDuration(Number(e.target.value))}
                      />
                    </div>
                  )}
                </div>
                {shareUrl.trim() !== "" && (
                  <div className="col-12 mb-2">
                    <label className="form-label">Share URL</label>
                    <input
                      type="text"
                      className="form-control"
                      value={shareUrl}
                      readOnly
                    />
                    <button
                      type="button"
                      className="btn btn-primary mt-2"
                      data-bs-dismiss="modal"
                      onClick={copyToClipboard}
                    >
                      Copy to Clipboard
                    </button>
                  </div>
                )}

                {shareUrl.trim() === "" && (
                  <div className="modal-footer d-sm-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      onClick={shareFolder}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Share Folder */}
        {/* Move File */}
        <div
          className="modal fade modal-default pos-modal"
          id="move-file"
          aria-labelledby="move-file"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header p-4 d-flex align-items-center justify-content-between">
                <h5>Move File</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body p-4">
                <div className="row">
                  <div className="col-12">
                    <label className="form-label">
                      Select Destination Folder
                    </label>
                    <select
                      name="newFolderId"
                      className="form-select"
                      onChange={handleInputChange}
                      value={localFormData.newFolderId}
                    >
                      <option value="">Select Folder</option>
                      {allFolders
                        .filter((folder) => folder.type === "folder")
                        .map((folder) => (
                          <option key={folder.id} value={folder.id}>
                            {folder.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="modal-footer d-sm-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={moveFile}
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Move
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Move File */}
        {/* Copy File */}
        <div
          className="modal fade modal-default pos-modal"
          id="copy-file"
          aria-labelledby="copy-file"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header p-4 d-flex align-items-center justify-content-between">
                <h5>Copy File</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body p-4">
                <div className="row">
                  <div className="col-12">
                    <label className="form-label">
                      Select Destination Folder
                    </label>
                    <select
                      name="newFolderId"
                      className="form-select"
                      onChange={handleInputChange}
                      value={localFormData.newFolderId}
                    >
                      <option value="">Select Folder</option>
                      {allFolders
                        .filter((folder) => folder.type === "folder")
                        .map((folder) => (
                          <option key={folder.id} value={folder.id}>
                            {folder.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="modal-footer d-sm-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={copyFile}
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Copy File */}
      </div>
    </>
  );
};

export default FileContent;

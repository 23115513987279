import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
//import { Permissions, Role, User, Apps } from "../../../lib/types/types";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
    persona: {},
    permission_matrix: {},
    apps: {},
    storage: {},
    entity: "",
  },
  reducers: {
    setAuthToken: (state, action) => {
      state.token = btoa(action.payload);
    },
    resetAuth: (state) => {
      state.token = "";
      state.persona = {};
      state.permission_matrix = {};
      state.apps = {};
      state.storage = {};
      state.entity = "";
    },
    setAuthUser: (state, action) => {
      state.persona = action.payload;
      state.permission_matrix = action.payload.permissions;
      state.apps = action.payload.apps;
    },
    setUserStorage: (state, action) => {
      state.storage = action.payload;
    },
    setEntity: (state, action) => {
      state.entity = action.payload;
    },
  },
});

export const {
  setAuthToken,
  resetAuth,
  setAuthUser,
  setUserStorage,
  setEntity,
} = authSlice.actions;
export const getToken = (state) => atob(state.auth.token);
export const getUser = (state) => state.auth.persona;
export const getUserStorage = (state) => state.auth.storage;
export const getRole = (state) => state.auth.role_matrix;
export const getPermission = (state) => state.auth.permission_matrix;
export const getApps = (state) => state.auth.apps;
export const getEntity = (state) => state.auth.entity;
export default authSlice.reducer;

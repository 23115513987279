import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { extensionImageMap } from "../../lib/types/extensions.tsx";
import { baseUrl } from "../../api/apiConfig";
import axios from "axios";
import { setupInterceptors } from "../../lib/interceptor.tsx";
import { Details } from "./details.jsx";

import { List, Star } from "react-feather";

import {
  resetCurrent,
  resetStack,
} from "../../core/redux/slices/folderSlice.jsx";

//Providers
import { store } from "../../core/redux/store.jsx";
import { getUser } from "../../core/redux/slices/authSlice";

const Favourites = () => {
  const options1 = [
    { value: "lastModified", label: "Last Modified" },
    { value: "lastModifiedByMe", label: "Last Modified by Me" },
    { value: "lastOpenedByMe", label: "Last Opened by Me" },
  ];

  const [files, setFiles] = useState([]);
  const [folderDetails, setFolderDetails] = useState([]);

  const user = getUser(store.getState());

  const axiosInstance = axios.create();
  setupInterceptors(axiosInstance);

  const fetchFavouriteFiles = () =>
    axiosInstance
      .get(`${baseUrl}/files`, { params: { state: "favourite" } })
      .then((response) => {
        const data = response.data.data;
        setFiles(data);
      })
      .catch((error) => {
        console.log(error);
      });

  useEffect(() => {
    store.dispatch(resetCurrent());
    store.dispatch(resetStack());
    fetchFavouriteFiles();
  }, []);

  const handleStarClick = (folder_Id) => {
    const action = "unfavourite";

    const payload = {
      action: action,
    };

    axiosInstance
      .post(`${baseUrl}/files/action/${folder_Id}`, payload)
      .then((response) => {
        fetchFavouriteFiles();
      })
      .catch((error) => {
        console.log("Error updating folder favorite status", error);
      });
  };

  const handleFolderClick = (folderId) => {
    axiosInstance
      .get(`${baseUrl}/files/${folderId}`)
      .then((response) => {
        const folderDetails = response.data.data;
        setFolderDetails(folderDetails);
        console.log(folderDetails.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className={` file-manager `}>
        <div className="card p-4 bg-white all-files mb-0">
          <div className="seprator-lg d-lg-flex align-items-center justify-content-between">
            <h4>Favourite Files</h4>
            <div className="d-sm-flex align-items-center btn-grp">
              <Link to="#" className="btn btn-primary me-2">
                <List className="feather-20" />
              </Link>
              <div className="icon-select">
                <Select className="select" options={options1} />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table datanew">
              <thead>
                <tr>
                  <th>
                    <label className="checkboxs">
                      <input type="checkbox" id="select-all" />
                      <span className="checkmarks" />
                    </label>
                  </th>
                  <th>Name</th>
                  <th>Last Modified</th>
                  <th>Size</th>
                  <th>Action</th>
                </tr>
              </thead>
              {files.map((folder, index) => {
                let fileExtension = null;

                const fileNameParts = folder.name.split(".");
                if (fileNameParts.length > 1) {
                  fileExtension = fileNameParts.pop().toLowerCase();
                }

                const imageSrc =
                  folder.type === "folder"
                    ? extensionImageMap["folder"]
                    : extensionImageMap[fileExtension] ||
                      extensionImageMap["document"];
                return (
                  <tbody key={index}>
                    <tr>
                      <td>
                        <label className="checkboxs">
                          <input type="checkbox" />
                          <span className="checkmarks" />
                        </label>
                      </td>
                      <td className="productimgname">
                        <Link
                          to="product-list"
                          className="dropdown-item popup-toggle product-img d-flex align-items-center"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          onClick={() => handleFolderClick(folder.id)}
                        >
                          <ImageWithBasePath
                            src={imageSrc}
                            alt="Product"
                            className="me-2"
                          />
                          <span>{folder.name}</span>
                        </Link>
                      </td>
                      <td>
                        {folder.updated_at}
                        <br />
                        {folder.owner.name}
                      </td>
                      <td>{folder.size}</td>
                      <td className="text-end">
                        <div className="d-flex align-items-center">
                          <Link
                            to="#"
                            className="me-2"
                            onClick={() => handleStarClick(folder.id)}
                          >
                            <Star
                              className="feather-16 me-2 color-secondary"
                              color={
                                folder.favourite ? "currentColor" : "orange"
                              }
                              fill={folder.favourite ? "none" : "orange"}
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </div>
      <Details filePreview={folderDetails} />
    </>
  );
};

export default Favourites;
